varying vec3 vColor;
varying vec2 vUv;

void main() {
    vec2 uv = gl_PointCoord;
    float distanceToCenter = length(uv - vec2(0.5));
    if(distanceToCenter > 0.5) {
        discard;
    }

    // Brightness
    float brightness = vColor.g;

    // gl_FragColor = vec4(vColor, 1.0);
    gl_FragColor = vec4(
        0.4 * brightness,
        brightness * (1.0 - vUv.y),
        0.9 * brightness, 1.0);

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}